.notification {
  color: #fff;
  font-size: 15px;
  padding: 0;
  text-align: center;
  position: fixed;
  z-index: 5000;
  bottom: 0px;
  right: 0px;
  margin: 10px;
  border: 3px solid #fff;
  width: 300px;

  &.error {
    background-color: #c94962;
  }

  &.success {
    background-color: #28a745;
  }

  &.warn {
    background-color: #ffc107;
    color: black;
  }

  &.info {
    background-color: #17a2b8;
  }

  &.show {
    -webkit-animation-duration: 500ms;
    animation-duration: 500ms;
    -webkit-animation-name: notify-show;
    animation-name: notify-show;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  &.hide {
    display: none;
    -webkit-animation-duration: 250ms;
    animation-duration: 250ms;
    -webkit-animation-name: notify-hide;
    animation-name: notify-hide;
    -webkit-animation-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
    animation-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);

    &:hover {
      -webkit-animation-play-state: paused;
      -moz-animation-play-state: paused;
      -o-animation-play-state: paused;
      animation-play-state: paused;
    }
  }

  .close {
    cursor: pointer;
    opacity: 0.5;
    padding: 10px 15px;
    float: right;
    color: black;
  }

  .message {
    cursor: default;
    display: block;
    padding: 10px 15px;
    color: #fff;
    font-size: 15px;
    text-align: center;
  }
}

@-webkit-keyframes notify-show {
  from {
    right: -310px;
  }
  to {
    right: 0;
  }
}

@keyframes notify-show {
  from {
    right: -310px;
  }
  to {
    right: 0;
  }
}
@-webkit-keyframes notify-hide {
  from {
    right: 0;
  }
  to {
    right: -310px;
  }
}
@keyframes notify-hide {
  from {
    right: 0;
  }
  to {
    right: -310px;
  }
}
